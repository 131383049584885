export const ForUs = () => {
    return (
        <>
            <section className="for-us">
                <h1>За нас</h1>
                <p className="for-us-description">
                    Алинеа се роди от идеята за възраждането на центъра на Асеновград. Събрахме се млади ентусиасти и с цяло сърце и креативност се впуснахме
                    в това приключение - не просто да готвим, а да творим кулинарни изкушения. Алинеа не е само място за хранене, а е място, където създаваме
                    приятни емоции и спомени. Менюто е съвкупност от местни и световни вкусотии. Всяка от съставките на нашите храни и напитки е внимателно 
                    подбрана и опитана от нашите специалисти. Екипът ни е сбор от професионалисти с дългогодишен опит в българската и чуждестранната кухня. 
                    Това, което отличава Алинеа, е вкусът, стимулиращ сетивата. Съчетан с приятна обстановка и сърдечно обслужване, с индивидуален подход към 
                    всеки наш гост, ние се стремим да създадем незабравимо преживяване. Алинеа е доказателство, че една малка идея може да се разрастне в нещо 
                    забележително с помощта на креативност, вдъхновение и страст.
                </p>
            </section>

            <p className="video">
                <iframe
                    src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fpetya.kertikova%2Fvideos%2F1090921551998504%2F&show_text=false&width=560&t=0"
                    width={560}
                    height={314}
                    style={{ border: "none", overflow: "hidden" }}
                    allowFullScreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
            </p>


        </>
    )
}