import { Header } from "./Header"
import { MenuHeader } from "./MenuHeader"

export const Home = () => {
    return (
        <>
            <Header />
            <MenuHeader />
            
        </>

    )
}